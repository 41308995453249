
// Solutions_gif.js
import React, { useRef, useEffect } from 'react';
import './SolutionsGif.css';
import ClampingGif from '../assets/Clamping.gif';
import GroundRollGif from '../assets/GroundRoll.gif';
import LiftoffGif from '../assets/Liftoff.gif';

function Solutions_gif() {
  const solutionsRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.3, // Trigger when 30% of the section is visible
    };

    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active');
          observer.unobserve(entry.target); // Stop observing after activation
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    const currentElement = solutionsRef.current; // Capture the current ref

    if (currentElement) {
      observer.observe(currentElement);
    }

    // Cleanup function using the captured ref
    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, []); // Empty dependency array ensures this runs once on mount

  return (
    <section className="solutions_gif" ref={solutionsRef}>
      <div className="solutions_container">
        {/* Step 1 */}
        <div className="solution_step">
          <div className="step_text">
            <span className="step_number">01</span>
            <h3 className="step_title">Extension & Clamping</h3>
            <p className="step_description">State of the Art Clamps secure the aircraft.</p>
          </div>
          <div className="step_image">
            <img src={ClampingGif} alt="Extension and Clamping Process" />
          </div>
        </div>

        {/* Step 2 */}
        <div className="solution_step reverse">
          <div className="step_image">
            <img src={GroundRollGif} alt="Electromagnetic Acceleration Process" />
          </div>
          <div className="step_text">
            <span className="step_number">02</span>
            <h3 className="step_title">Electromagnetic Acceleration</h3>
            <p className="step_description">Accelerate electromagnetically while the engines are idling.</p>
          </div>
        </div>

        {/* Step 3 */}
        <div className="solution_step">
          <div className="step_text">
            <span className="step_number">03</span>
            <h3 className="step_title">De-clamp & Take-Off</h3>
            <p className="step_description">Disengage at take-off speed with engines at full power.</p>
          </div>
          <div className="step_image">
            <img src={LiftoffGif} alt="De-clamping and Take-Off Process" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Solutions_gif;


