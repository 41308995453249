// Footer.js
import React, { useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate and useLocation
import './Footer.css';
import LogoPc from '../assets/logo_pc.png';
import TwitterIcon from '../assets/twitter2.png'; // Ensure you have these icons in src/assets
import LinkedInIcon from '../assets/linkedin_icon.png';

function Footer() {
  const footerRef = useRef(null);
  const navigate = useNavigate(); // Initialize useNavigate
  const location = useLocation(); // Get current location (route)

  useEffect(() => {
    const observerOptions = {
      threshold: 0.3, // Trigger when 30% of the footer is visible
    };

    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active');
          observer.unobserve(entry.target); // Stop observing after activation
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    const currentElement = footerRef.current; // Capture the current ref

    if (currentElement) {
      observer.observe(currentElement);
    }

    // Cleanup function using the captured ref
    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, []); // Empty dependency array ensures this runs once on mount

  // Function to scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // Function for Home button: Navigate if not on home, else scroll to top
  const handleHomeClick = () => {
    if (location.pathname === '/') {
      scrollToTop(); // Scroll to top if already on the home page
    } else {
      navigate('/'); // Navigate to home page if on a different page
    }
  };

  // Function for Contact button: Navigate if not on contact, else scroll to top
  const handleContactClick = () => {
    if (location.pathname === '/contact') {
      scrollToTop(); // Scroll to top if already on the contact page
    } else {
      navigate('/contact'); // Navigate to contact page if on a different page
    }
  };

  return (
    <footer className="footer" ref={footerRef}>
      <div className="footer_container">
        {/* Logo */}
        <div className="footer_logo">
          <img src={LogoPc} alt="Kon Aerosystems Logo" className="footer_logo_image" />
        </div>

        {/* Social Links */}
        <div className="footer_social">
          <a
            href="https://x.com/konaerosystems"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Twitter (X) - Kon Aerosystems"
          >
            <img src={TwitterIcon} alt="Twitter (X) Logo" className="social_icon" />
          </a>
          <a
            href="https://www.linkedin.com/company/kon-aerosystems/?originalSubdomain=ca"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn - Kon Aerosystems"
          >
            <img src={LinkedInIcon} alt="LinkedIn Logo" className="social_icon" />
          </a>
        </div>

        {/* Navigation Buttons */}
        <div className="footer_navigation">
          <button className="nav_button" onClick={handleHomeClick}>
            Home
          </button>
          <button className="nav_button" onClick={handleContactClick}>
            Contact
          </button>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
