import React from 'react';
import './Mission.css';

function Mission() {
  return (
    <section className="mission">
      <div className="mission-content">
        <h2 className="mission-title">Our Mission</h2>
        <p className="mission-text">
          <span className="highlight kon-aerosystems">Kon Aerosystems</span> is at the forefront of sustainable aviation technology with the development of the{' '}
          <span className="highlight etols">
            &nbsp;Electromagnetic Take-Off Launch System (eTOLS)&nbsp;
          </span>
        </p>
        <p className="mission-text">
          Our innovation aims to significantly reduce the aviation industry's environmental impact while offering economic benefits for airlines and airports.
        </p>
      </div>
    </section>
  );
}

export default Mission;
