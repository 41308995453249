// Benefits.js
import React, { useState, useRef, useEffect } from 'react';
import './Benefits.css';
import Benefit1 from '../assets/Benefit1.png';
import Benefit2 from '../assets/Benefit2.png';
import Benefit3 from '../assets/Benefit3.png';

function Benefits() {
  const [activeBenefit, setActiveBenefit] = useState(1);
  const benefitsRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.3, // Trigger when 30% of the section is visible
    };

    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active');
          observer.unobserve(entry.target); // Stop observing after activation
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    const currentElement = benefitsRef.current; // Capture the current ref

    if (currentElement) {
      observer.observe(currentElement);
    }

    // Cleanup function using the captured ref
    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, []); // Empty dependency array ensures this runs once on mount

  // Function to handle benefit selection
  const handleBenefitClick = (benefitNumber) => {
    setActiveBenefit(benefitNumber);
  };

  return (
    <section className="benefits" ref={benefitsRef}>
      <div className="benefits_container">
        <h2 className="benefits_title">Benefits of eTOLS</h2>
        <div className="benefits_menu">
          <button
            className={`benefit_button ${activeBenefit === 1 ? 'active' : ''}`}
            onClick={() => handleBenefitClick(1)}
          >
            01
          </button>
          <button
            className={`benefit_button ${activeBenefit === 2 ? 'active' : ''}`}
            onClick={() => handleBenefitClick(2)}
          >
            02
          </button>
          <button
            className={`benefit_button ${activeBenefit === 3 ? 'active' : ''}`}
            onClick={() => handleBenefitClick(3)}
          >
            03
          </button>
        </div>
        <div className="benefits_content">
          {activeBenefit === 1 && (
            <div className="benefit_item">
              <img src={Benefit1} alt="Benefit 1: Extension & Clamping" className="benefit_image" />
              <div className="benefit_text">
              </div>
            </div>
          )}
          {activeBenefit === 2 && (
            <div className="benefit_item">
              <img src={Benefit2} alt="Benefit 2: Electromagnetic Acceleration" className="benefit_image" />
              <div className="benefit_text">
              </div>
            </div>
          )}
          {activeBenefit === 3 && (
            <div className="benefit_item">
              <img src={Benefit3} alt="Benefit 3: De-clamp & Take-Off" className="benefit_image" />
              <div className="benefit_text">
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default Benefits;
