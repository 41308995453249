import React from 'react';
import './introduction.css';

function Introduction() {
  return (
    <section className="introduction">
      <h1 className="tagline">Building the Future of Sustainable Aviation <span className="highlight">One Take-Off at a Time</span></h1> 
      <p className="intro-text">
        Join our community to be the first to know about our latest innovations in sustainable aviation engineering and receive updates on the latest projects we are working on.
      </p>
    </section>
  );
}

export default Introduction;
