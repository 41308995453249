import React, { useEffect, useState } from 'react';
import JovanImage from '../assets/Jovan.png';
import JavadImage from '../assets/Javad.png';
import './Contact.css';

function Contact() {
  const [isPcView, setIsPcView] = useState(false);

  useEffect(() => {
    // Function to update the view based on window width
    const updateView = () => {
      setIsPcView(window.innerWidth >= 769);
    };

    // Initial check
    updateView();

    // Add event listener on resize
    window.addEventListener('resize', updateView);

    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', updateView);
  }, []);

  return (
    <div className="contact-page">
      <div className="contact-content">
        <div className="contact-info-container">
          <div className="contact-info">
            <h2>Contact Us</h2>
            <br />
            <p><strong>Address:</strong></p>
            <p>175 Bloor St E</p>
            <p>Toronto, ON, M4W 3R8</p>

            {/* Spacer div to fine-tune spacing */}
            {isPcView && <div style={{ height: '55px' }}></div>} {/* Adjust the height to bring button up */}
            
            <button
              className="email-button"
              onClick={() => window.location.href = 'mailto:jovan@konaerosystems.com'}
            >
              Email Us
            </button>
          </div>
        </div>
        <div className="founders-container">
          <div className="founder">
            <img src={JovanImage} alt="Jovan Phull" className="founder-image" />
            <div className="founder-info">
              <h2>Jovan Phull</h2>
              <p>CEO</p>
              <a href="https://www.linkedin.com/in/jovan-p-343706122/" target="_blank" rel="noopener noreferrer">
                LinkedIn
              </a>
            </div>
          </div>
          <div className="founder">
            <img src={JavadImage} alt="Javad Siahkamari" className="founder-image" />
            <div className="founder-info">
              <h2>Javad Siahkamari</h2>
              <p>CTO</p>
              <a href="https://www.linkedin.com/in/javad-siahkamari/" target="_blank" rel="noopener noreferrer">
                LinkedIn
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
