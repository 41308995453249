import React, { useRef, useEffect } from 'react';
import './Solution.css';
import logoMobile from '../assets/logo_mobile.png'; // Import the mobile logo

function Solution() {
  const solutionRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.3, // Trigger when 30% of the section is visible
    };

    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active');
          observer.unobserve(entry.target); // Stop observing after activation
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    const currentElement = solutionRef.current; // Capture the current ref

    if (currentElement) {
      observer.observe(currentElement);
    }

    // Cleanup function using the captured ref
    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, []); // Empty dependency array ensures this runs once on mount

  return (
    <section className="solution" ref={solutionRef}>
      <div className="solution-content">
        <div className="solution-logo">
          <img src={logoMobile} alt="Kon Aerosystems Logo" />
        </div>
        <h2 className="solution-title">Our Solution</h2>
        <p className="solution-paragraph">
          eTOLS utilizes electromagnetic motors to assist aircraft during take-off. This system involves a retractable arm and clamping mechanism attached to the aircraft's landing gears, accelerating the plane electromagnetically while engines remain at idle.
        </p>
      </div>
    </section>
  );
}

export default Solution;
